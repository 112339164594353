import React from 'react';

function Hero({ mediaType, mediaSrc }) {
    const isVideo = mediaType === 'video';

    return (
        <div className="relative bg-cover bg-bottom" style={{ height: '60vh', maxHeight: '720px' }}>
            {isVideo ? (
                <video autoPlay loop muted className="absolute top-0 left-0 w-full h-full object-cover">
                    <source src={mediaSrc} type="video/mp4" />
                </video>
            ) : (
                <div style={{ backgroundImage: `url(${mediaSrc})`, backgroundSize: 'cover', width: '100%', height: '100%' }}></div>
            )}

            <div className="absolute inset-0 flex items-center justify-center sm:w-full md:w-1/2 mx-auto">
                <div className="text-center">
                    <p className="text-white font-extrabold text-3xl md:text-5xl pb-10">
                        The Last Expanse
                    </p>
                    <p className="w-4/5 mx-auto text-base sm:text-lg md:text-l lg:text-xl text-gray-200 pb-5">
                        Dive into the high-velocity journey, where the roar of hoverbikes echoes through forgotten landscapes shrouded in mystery. The remnants of the past await - are you ready to chase the unknown?
                    </p>
                    <button className="w-2/4 mx-auto flex-1 block md:inline-block appearance-none highlight_red text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow hover:bg-red-800">Coming Soon</button>
                </div>
            </div>
        </div>
    );
}

export default Hero;