import React from 'react';
import Hero from "../components/Hero";
import video from "../assets/desert.mp4";
import SubscribeSocials from "../components/Subscribe_Socials";
import ImageRow from "../components/ImageRow";

function Landing() {
    return (
        <>
            <Hero mediaType="video" mediaSrc={video} />
            <div className="container mx-auto font-sans dark_background text-white rounded md:p-16 text-center py-5">
                <h2 className="font-bold break-normal text-xl md:text-3xl">More to come...</h2>
                <h3 className="w-3/5 mx-auto break-normal pt-4 font-normal text-gray-300 text-base md:text-xl">Get a glimpse of both concept and in-game artwork. Stay tuned for more reveals.</h3>
            </div>
            <ImageRow />
            <SubscribeSocials />
        </>
    );
}
export default Landing;