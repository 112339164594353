import React from 'react';
import ZoomImage from './ZoomImage';
import img_1 from '../assets/images/concept_house.png';
import img_2 from '../assets/images/concept_scrap.png';
import img_3 from '../assets/images/concept_promo.png';

function ImageRow() {

    return (
        <div className="flex justify-center pt-4">
            <ZoomImage src={img_1} alt="Scrap is part of life!" text={"Explore a world built of remnants!"}/>
            <ZoomImage src={img_2} alt="Salvage from a forgotten time!" text={"Salvage from a forgotten time!"}/>
            <ZoomImage src={img_3} alt="Hoverbike Concept Art" text={"Adventure awaits..."}/>
        </div>
    );
}

export default ImageRow;