import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SteamIcon from "../assets/icons/steam.svg";
import TwitterIcon from "../assets/icons/twitter.svg";
import TwitchIcon from "../assets/icons/twitch.svg";

function SubscribeSocials() {

    return (
        <div className="container mx-auto font-sans dark_background text-white rounded md:p-16 text-center py-5">
            <ToastContainer />
            <h2 className="font-bold break-normal text-2xl md:text-4xl">Stay in the Loop!</h2>
            <h3 className="w-3/5 mx-auto break-normal pt-4 font-normal text-gray-300 text-base md:text-xl">Follow progress on socials to receive updates, sneak peeks, and special offers. Don't miss out—be part of our gaming community!</h3>
            <div className="w-full text-center pt-4">
                <div className="max-w-xl mx-auto pr-0 flex flex-wrap items-center justify-evenly flex-1 md:mt-0 block highlight_red text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow">
                    <a href="https://steamcommunity.com/id/Dair_Games/" target="_blank" rel="noopener noreferrer">
                        <img src={SteamIcon} className="h-8 w-8" alt="Steam Icon" />
                    </a>
                    <a href="https://twitter.com/DairGames" target="_blank" rel="noopener noreferrer">
                        <img src={TwitterIcon} className="h-8 w-8" alt="Twitter Icon" />
                    </a>
                    <a href="https://www.twitch.tv/dairgames" target="_blank" rel="noopener noreferrer">
                        <img src={TwitchIcon} className="h-8 w-8" alt="Twitch Icon" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default SubscribeSocials;

