import React, { useState } from 'react';
import logo from "../assets/images/logo_new_500.png";
import SteamIcon from "../assets/icons/steam.svg";
import TwitterIcon from "../assets/icons/twitter.svg";
import TwitchIcon from "../assets/icons/twitch.svg";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <nav className="w-full dark_background_2 text-white shadow-sm font-mono flex flex-col sm:flex-row items-center justify-between px-4 mb-4" style={{ minHeight: '60px' }}>
            <div className="w-full sm:w-4/5 mx-auto flex flex-col sm:flex-row justify-between items-center">
                <div className="flex items-center justify-between w-full sm:w-auto px-2 py-2">
                    <img
                        src={logo}
                        alt="logo"
                        className="h-auto w-20 sm:w-32 md:w-48 lg:w-64 object-contain"
                        style={{ maxHeight: '5rem', objectFit: 'contain' }}
                    />
                    <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="sm:hidden">
                        {/* Replace with hamburger icon */}
                        <span className="text-lg">Menu</span>
                    </button>
                </div>
                <div className={`flex-grow ${isMenuOpen ? 'flex' : 'hidden'} sm:flex flex-col sm:flex-row items-center justify-center text-sm md:text-lg`}>
                    <a href="/" className="py-2 px-4 lg:px-12">Home</a>
                    <a href="/" className="py-2 px-4 lg:px-12">Gallery</a>
                    <a href="/" className="py-2 px-4 lg:px-12">News</a>
                    {/* Add social icons on small screens */}
                    <div className="flex sm:hidden">
                        <a href="https://steamcommunity.com/id/Dair_Games/" target="_blank" rel="noopener noreferrer" className="pr-2">
                            <img src={SteamIcon} className="h-8 w-8" alt="Steam Icon" />
                        </a>
                        <a href="https://twitter.com/DairGames" target="_blank" rel="noopener noreferrer" className="pr-2">
                            <img src={TwitterIcon} className="h-8 w-8" alt="Twitter Icon" />
                        </a>
                        <a href="https://www.twitch.tv/dairgames" target="_blank" rel="noopener noreferrer" className="pr-2">
                            <img src={TwitchIcon} className="h-8 w-8" alt="Twitch Icon" />
                        </a>
                    </div>
                </div>
                <div className="hidden sm:flex items-center justify-end">
                    <a href="https://steamcommunity.com/id/Dair_Games/" target="_blank" rel="noopener noreferrer" className="pr-2">
                        <img src={SteamIcon} className="h-8 w-8 sm:h-6 sm:w-6" alt="Steam Icon" />
                    </a>
                    <a href="https://twitter.com/DairGames" target="_blank" rel="noopener noreferrer" className="pr-2">
                        <img src={TwitterIcon} className="h-8 w-8 sm:h-6 sm:w-6" alt="Twitter Icon" />
                    </a>
                    <a href="https://www.twitch.tv/dairgames" target="_blank" rel="noopener noreferrer" className="pr-2">
                        <img src={TwitchIcon} className="h-8 w-8 sm:h-6 sm:w-6" alt="Twitch Icon" />
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;