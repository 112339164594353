import React from 'react';
import Footer from './components/Footer';
import Landing from "./pages/Landing";
import Navbar from "./components/Navbar";


function App() {
    return (
        <div className="dark_background">
            {/*<Header />*/}
            <Navbar />
            <Landing />
            <Footer />
        </div>
);
}

export default App;
