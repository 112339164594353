import React from 'react';

function ZoomImage({ src, alt, text }) {
    return (
        <div className="relative overflow-hidden cursor-pointer w-full">
            <img className="transform transition-transform ease-in duration-500 scale-100 hover:scale-110 w-full h-full"
                 src={src}
                 alt={alt} />
            <div className="absolute bottom-0 left-0 right-0 text-white text-center">
                <p className="text-base sm:text-xl md:text-3xl font-bold pb-5">{text}</p>
            </div>

        </div>
    );
}

export default ZoomImage;
